body {
    background-color: #FFFFFF;
}

.main-color1, .testbutton, .button, .item .cart .button-cart, .item .wishlist:hover, .item .compare:hover, .product-grid .product-buttons .cart, .product-grid .product-buttons .compare:hover, .product-list .product-buttons .compare:hover, .product-grid .product-buttons .wishlist:hover, .product-list .product-buttons .wishlist:hover, .btn-primary, .da-arrows span, .da-slide .da-link, .fastorder-panel-default .panel-heading, .slideshow-box .slideshow-plus-link {
    background-color: #F4EDE1 !important;
}

.main-color2, .main-color1:hover, .testbutton:hover, .cart-top, .button:hover, .item .cart .button-cart:hover, .item .wishlist, .item .compare, .product-grid .product-buttons .cart:hover, .product-grid .product-buttons .wishlist, .product-list .product-buttons .wishlist, .product-grid .product-buttons .compare, .product-list .product-buttons .compare, .caption .intro-btn a, .search-box-top input[type=submit], .da-arrows span:hover, .da-slide .da-link:hover, ul.account-ul li:hover, .account-content .buttons div .button, .wishlist-tr, .account-content .button, .slideshow-box .slideshow-plus-link:hover {
    background-color: #DADADA !important;
}

.main-color2-text, .main-color1-text:hover, #sstore_menu > ul > li > div.dropdown a:hover, .error, .success, .search-top .fa-search, ul.account-ul li i, input[type=search]::-webkit-input-placeholder, .account-content form legend i, .account-content .buttons div .button-back, .account-content .table-div table .button-back, .account-content h2 i, #right-block i {
    color: #DADADA;
}

.button, .product-grid .product-buttons .cart, .testbutton, .slideshow-box .slideshow-plus-link {
    color: #000000 !important;
}

.top-panel a, #sidebar a, .navbar-fixed-bottom ul li a, .sidebar-a, .settings span, .settings i, .settings strong {
    color: #FFFFFF;
}

.top-panel, #sidebar, .search-box-top, .navbar-fixed-bottom, .sidebar-a .dropdown-menu {
    background: #000000;
}

.top-panel a:hover, .settings:hover span {
    color: #9C9C9C;
}

.enter:before {
    background: url(/catalog/view/theme/storeset/images/enter.png) no-repeat;
}

.register:before {
    background: url(/catalog/view/theme/storeset/images/reg.png) no-repeat;
}

#phone a, #contacts .contacts-fa {
    color: #000000;
}

#shedule {
    color: #000000;
}

#cart {
    background-color: #F4EDE1;
}

#right-block .fa-shopping-bag::before {
    color: #F4EDE1;
}

#cart #cart-total, #cart a {
    color: #000000;
}

nav, .sb-icon-search, .sb-search.sb-search-open .sb-icon-search, .no-js .sb-search .sb-icon-search {
    background: #FFFFFF;
}

nav .main-ul > .main-li > a, nav .main-ul > .main-li > a:visited, #sstore_menu > ul > li > a, #sstore_menu > ul > li > a:visited, ul.mobile-category > li > a:hover, ul.mobile-category > li ul > li:hover > a, #megamenu-menu .nav > li > a {
    color: #000000;
}

@media only screen and (max-width: 992px) {
    #megamenu-menu .nav > li > div a {
        color: #000000 !important;
    }
}

nav .main-ul > .main-li:hover > a, #sstore_menu > ul > li:hover a, #megamenu-menu .nav > li > a:hover {
    color: #9C9C9C;
}

.category-dropdown, #sstore_menu > ul > li > div.dropdown, #megamenu-menu .nav > li > div {
    background: #FFFFFF;
}

.category-dropdown > .sub-ul > .sub-li a.sub-category, .category-dropdown > .sub-ul > .sub-li ul li a, #sstore_menu > ul > li > div.dropdown a, #megamenu-menu .nav > li > div a {
    color: #000000;
}

#search input {
    background: #FFFFFF;
}

#search input {
    background-position: 6px 6px;
    background-repeat: no-repeat;
}

.box-heading {
    background: #F4EDE1;
}

.box-heading {
    color: #000000;
}

ul.box-category li a, ul.box-category li a:visited {
    color: #000000;
}

#sstore-3-level > ul > li > a {
    background: #F4EDE1;
}

#sstore-3-level ul ul li a {
    background: #FFFFFF;
}

#sstore-3-level > ul > li > a {
    color: #000000;
}

#sstore-3-level > ul > li > a:hover {
    color: #9C9C9C;
}

#sstore-3-level ul ul li a {
    color: #000000;
}

#sstore-3-level ul ul li a:hover {
    color: #9C9C9C;
}

#sstore-3-level ul ul ul li a.current-link {
    color: #000000;
}

.product-list .price-old, .product-grid .price-old {
    color: #DADADA;
}

.product-list .price-new, .product-grid .price-new, .product-grid .price, .item .price, .swiper-slide .price-new {
    color: #000000;
}

.product-info > .right .price-new {
    color: #000000;
}

.product-info .price-new {
    color: #000000;
}

.product-info > .right .cart .button {
    color: #000000;
}

.product-info > .right .cart .button:hover {
    background: url(/catalog/view/theme/storeset/images/to-cart-hover.png) no-repeat 16% 46%, #ff5100;
}

.footer-top {
    background: #FFFFFF;
}

p.subscribe, footer .socials a, footer .contacts ul li, footer .contacts ul li a, .terms-footer-block, .terms-footer-block a {
    color: #000000;
}

footer h3 {
    color: #000000;
}

footer h3.top-f {
    color: #000000;
}

.footer-top .about p, .footer-top .about p a {
    color: #000000;
}

.footer-middle {
    background: #000000;
}

footer .footer-middle ul > li > a, footer .footer-middle ul > li > a:visited, footer .footer-middle ul > li > a:hover {
    color: #000000;
}

#powered {
    background: #000000;
}

#powered .copyright {
    color: #FFFFFF;
}

#powered .copyright a, #powered .copyright a:visited {
    color: #000000;
}

#sidebar-toggle span, #search-top span, .login-top-mobile span, .cart-top-mobile span {
    color: #FFFFFF;
}

@media only screen and (max-width: 768px) {
    .top-panel, .search-box-top {
        background: #000000;
    }
}

#sidebar {
    background: #000000;
}

#sidebar a, .sidebar-a i, .sidebar-a strong, .sidebar-a .dropdown-menu .btn-link, .sidebar-a h3, .sidebar-a ul li {
    color: #FFFFFF;
}

.navbar-fixed-bottom {
    display: none;
}

#contactsservvwq1 {
    visibility: hidden;
}

.nav-tab.nav-tab--primary.tab-community {
    display: none;
}

.news-full-desc .img-thumbnail {
    display: none;
}

#mfilter-box-3 li[data-id="161"] {
    display: block;
}
