@font-face {
    font-family: 'icomoon';
    src:  url('../fonts/icomoon.eot?5yytzu');
    src:  url('../fonts/icomoon.eot?5yytzu#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?5yytzu') format('truetype'),
    url('../fonts/icomoon.woff?5yytzu') format('woff'),
    url('../fonts/icomoon.svg?5yytzu#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-bottom-arrow:before {
    content: "\e9000";
}
.icon-check:before {
    content: "\e90b";
}
.icon-e-commerce-like-heart:before {
    content: "\e90c";
}
.icon-envelope:before {
    content: "\e9033";
    color: #e8e8eb;
}
.icon-lawyer:before {
    content: "\e90d";
}
.icon-left-arrow:before {
    content: "\e9055";
}
.icon-lock:before {
    content: "\e9066";
    color: #e8e8eb;
}
.icon-minus:before {
    content: "\e90f";
}
.icon-order-by:before {
    content: "\e9088";
}
.icon-phone:before {
    content: "\e9099";
}
.icon-phone-call:before {
    content: "\e90e";
}
.icon-plus:before {
    content: "\e910";
}
.icon-return:before {
    content: "\e900";
}
.icon-search:before {
    content: "\e90dd";
    color: #fff;
}
.icon-shopping-cart:before {
    content: "\e901";
}
.icon-smartphone:before {
    content: "\e90ff";
}
.icon-star:before {
    content: "\e902";
}
.icon-tick:before {
    content: "\e911";
    color: #fff;
}
.icon-time-left:before {
    content: "\e903";
}
.icon-why-we-best-prices:before {
    content: "\e904";
}
.icon-why-we-comfortable-payment:before {
    content: "\e905";
}
.icon-why-we-discounts:before {
    content: "\e906";
}
.icon-why-we-fast-shippment:before {
    content: "\e907";
}
.icon-why-we-online-consultation:before {
    content: "\e908";
}
.icon-why-we-original-products:before {
    content: "\e909";
}
.icon-why-we-reliable-partners:before {
    content: "\e90a";
}